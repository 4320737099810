import React, { useEffect, useState } from "react";
import { MappingWindow } from "generic";
import { useSelector } from "react-redux";

import { PdSideBar } from "pipedrive-frontend";
import { CmsRichText } from "cms";

import { IMappingSlice } from "generic/types/mappingTypes";
import { IPdInitialState, IFieldItem } from "pipedrive-frontend/types/types";
import { IFortnoxStates } from "fortnox-frontend/types/fortnoxTypes";

const InvoiceFieldMappings = () => {
  const [orgFilteredFields, setOrgFilteredFields] = useState<IFieldItem[]>([]);
  const [personFilteredFields, setPersonFilteredFields] = useState<
    IFieldItem[]
  >([]);

  const {
    mapping: {
      defaultMappings: { invoiceSync },
    },
  } = useSelector((state: { mappings: IMappingSlice }) => state.mappings);

  const {
    fields: {
      data: { deal, organization, person },
    },
    pipedriveUserIds,
  } = useSelector((state: { pipedrive: IPdInitialState }) => state.pipedrive);

  const {
    fields: {
      data: { invoice },
    },
  } = useSelector((state: { fortnox: IFortnoxStates }) => state.fortnox);

  const triggerAppObject = {
    deal: deal,
    organization: orgFilteredFields,
    person: personFilteredFields,
  };

  const actionAppObject = {
    invoice: invoice,
  };

  const labels: { [k: string]: string } = {
    actionApp: "Fortnox Fields",
    triggerApp: "Pipedrive Fields",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (organization && organization.length > 0) {
      const filtered = organization.filter((field) => field.CRF !== "doNotAdd");
      setOrgFilteredFields(filtered);
    }
  }, [organization]);

  useEffect(() => {
    if (person && person.length > 0) {
      const filtered = person.filter((field) => field.CRF !== "doNotAdd");
      setPersonFilteredFields(filtered);
    }
  }, [person]);

  return (
    <div>
      <div className="my-4">
        <CmsRichText
          path="cmsContent.settings.invoices.fieldMappings"
          cssName="pipedrive"
        />
      </div>
      {invoiceSync &&
        deal &&
        deal.length > 0 &&
        invoice &&
        invoice.length > 0 && (
          <MappingWindow
            mappings={invoiceSync}
            primaryTriggerAppObjectKey="deal"
            triggerAppObject={triggerAppObject}
            actionAppObject={actionAppObject}
            mappingsFor="invoiceSync"
            labels={labels}
            cssClass="mapping-window"
            removeBorderThickness={true}
            isBordered={true}
            SideBar={PdSideBar}
            userIds={pipedriveUserIds}
            isAccordion={true}
          />
        )}
    </div>
  );
};

export default InvoiceFieldMappings;
