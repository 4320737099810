import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  onChangeCurrentWindow,
  fetchPipedriveFields,
  updatePipedriveUserIds,
  PdIntroPage,
  Loading,
} from "pipedrive-frontend";

import { CmsRichText } from "cms";

import pdLogo from "../assets/images/pipedrive_logo.jpeg";
import fortnoxLogo from "./../assets/images/fortnoxLogo.png";

import { fetchFortnoxFields } from "fortnox-frontend";

import { setShowErrorModal, fetchPipelines } from "generic";

import { ICmsData } from "cms/types/cmsTypes";
import { IPdInitialState } from "pipedrive-frontend/types/types";
import { IPreferenceStates } from "generic/types/preferencetypes";

const IntroPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    cmsData: { loading: cmsLoading },
  } = useSelector((state: { cms: ICmsData }) => state.cms);

  const { pipedriveUserIds } = useSelector(
    (state: { pipedrive: IPdInitialState }) => state.pipedrive
  );

  const { isAppConnected } = useSelector(
    (state: { preference: IPreferenceStates }) => state.preference
  );

  const changeCurrentWindow = () => {
    const companyId = searchParams.get("companyId");
    const userId = searchParams.get("userId");
    dispatch(onChangeCurrentWindow({ currentWindow: "Connect Window" }));
    navigate(
      `../${window.location.pathname}?companyId=${companyId}&userId=${userId}&page=connectPage`
    );
  };

  useEffect(() => {
    const companyId = searchParams.get("companyId");
    const userId = searchParams.get("userId");

    if (companyId) {
      dispatch(updatePipedriveUserIds({ companyId, userId }));
    }
  }, []);

  useEffect(() => {
    if (pipedriveUserIds && Object.keys(pipedriveUserIds).length > 0) {
      dispatch(fetchPipedriveFields({ userIds: pipedriveUserIds }));
      dispatch(fetchPipelines({ userIds: pipedriveUserIds }));
    }
  }, [pipedriveUserIds]);

  useEffect(() => {
    if (
      pipedriveUserIds &&
      Object.keys(pipedriveUserIds).length > 0 &&
      isAppConnected
    ) {
      dispatch(
        fetchFortnoxFields({ userIds: pipedriveUserIds, setShowErrorModal })
      );
    }
  }, [pipedriveUserIds, isAppConnected]);

  return (
    <div>
      {cmsLoading ? (
        <Loading />
      ) : (
        <PdIntroPage
          triggerAppLogo={pdLogo}
          actionAppLogo={fortnoxLogo}
          changeCurrentWindow={changeCurrentWindow}
          CmsRichText={CmsRichText}
        />
      )}
    </div>
  );
};

export default IntroPage;
