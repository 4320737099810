import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { MappingWindow } from "generic";
import { CmsRichText } from "cms";
import { PdSideBar, Loading } from "pipedrive-frontend";

import { IMappingSlice } from "generic/types/mappingTypes";
import { IPdInitialState, IFieldItem } from "pipedrive-frontend/types/types";
import { IFortnoxStates } from "fortnox-frontend/types/fortnoxTypes";

const OrganizationFieldMappings = () => {
  const [filteredFields, setFilteredFields] = useState<IFieldItem[]>([]);

  const {
    mapping: {
      defaultMappings: { customerSync },
    },
  } = useSelector((state: { mappings: IMappingSlice }) => state.mappings);

  const {
    fields: {
      data: { organization },
    },
    pipedriveUserIds,
  } = useSelector((state: { pipedrive: IPdInitialState }) => state.pipedrive);

  const {
    fields: {
      data: { customer },
    },
  } = useSelector((state: { fortnox: IFortnoxStates }) => state.fortnox);

  const triggerAppObject = {
    organization: filteredFields,
  };

  const actionAppObject = {
    customer: customer,
  };

  const labels: { [k: string]: string } = {
    actionApp: "Fortnox Fields",
    triggerApp: "Pipedrive Fields",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (organization && organization.length > 0) {
      const filtered = organization.filter((field) => field.CRF !== "doNotAdd");
      setFilteredFields(filtered);
    }
  }, [organization]);

  return (
    <div>
      <div className="my-4">
        <CmsRichText
          path="cmsContent.settings.contacts.organizationFieldMappings"
          cssName="pipedrive"
        />
      </div>
      {organization &&
      organization.length > 0 &&
      customer &&
      customer.length > 0 ? (
        <MappingWindow
          mappings={customerSync}
          primaryTriggerAppObjectKey="organization"
          triggerAppObject={triggerAppObject}
          actionAppObject={actionAppObject}
          mappingsFor="customerSync"
          labels={labels}
          cssClass="mapping-window"
          userIds={pipedriveUserIds}
          removeBorderThickness={true}
          isBordered={true}
          SideBar={PdSideBar}
          isAccordion={true}
        />
      ) : (
        <div className="d-flex flex-row justify-content-center">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default OrganizationFieldMappings;
