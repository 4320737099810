import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Settings from "./pages/Settings";
import SetupGuidePage from "./pages/SetupGuidePage";
import FinancialIframePage from "./pages/FinancialIframePage";
import RetriggerPage from "./pages/RetrrigerPage";
import Redirect from "./pages/Redirect";
import Setup from "./pages/Setup";
import Subscription from "./pages/Subscription";
import { setShowErrorModal, setShowSuccessModal } from "generic";
import { SuccessModal, ErrorModal } from "pipedrive-frontend";
import { CmsRichText, Notifications } from "cms";

function App() {
  return (
    <Router>
      <SuccessModal CmsRichText={CmsRichText} />
      <ErrorModal CmsRichText={CmsRichText} />
      <Routes>
        <Route path="/syncSettings" element={<Settings />} />
        <Route path="/setupGuide" element={<SetupGuidePage />} />
        <Route path="/redirect" element={<Redirect />} />
        <Route path="/retrigger" element={<RetriggerPage />} />
        <Route path="/financial" element={<FinancialIframePage />} />
        <Route path="/setup" element={<Setup />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route
          path="/cms-notifications"
          element={
            <Notifications
              setShowErrorModal={setShowErrorModal}
              setShowSuccessModal={setShowSuccessModal}
            />
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
