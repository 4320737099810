import { configureStore } from "@reduxjs/toolkit";

import { fortnoxSlice } from "fortnox-frontend";
import { cmsSlice } from "cms";
import { pipedriveSlice } from "pipedrive-frontend";
import {
  mappingSlice,
  contactSlice,
  productSlice,
  subscriptionSlice,
  preferenceSlice,
  invoiceSlice,
  financialSlice,
} from "generic";

const store = configureStore({
  reducer: {
    mappings: mappingSlice,
    contact: contactSlice,
    products: productSlice,
    fortnox: fortnoxSlice,
    invoice: invoiceSlice,
    subscription: subscriptionSlice,
    preference: preferenceSlice,
    cms: cmsSlice,
    pipedrive: pipedriveSlice,
    financial: financialSlice,
  },
});

export default store;
