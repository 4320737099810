export const showTooltipText = ({
  currencyPreference,
  paymentTermPreference,
  listOfPricePreference,
  waysOfDeliveryPreference,
  termsOfDeliveryPreference,
  vatTypePreference,
  languagePreference,
}) => {
  let tooltipText = "";
  if (!paymentTermPreference) {
    tooltipText =
      "The Payment Terms option has not been selected. Please select it before proceeding.";
  } else if (!currencyPreference) {
    tooltipText =
      "Currency option has not been selected. Please select it before proceeding.";
  } else if (!listOfPricePreference) {
    tooltipText =
      "Price list option has not been selected. Please select it before proceeding.";
  } else if (!waysOfDeliveryPreference) {
    tooltipText =
      "Ways of delivery option has not been selected. Please select it before proceeding.";
  } else if (!termsOfDeliveryPreference) {
    tooltipText =
      "Terms of delivery option has not been selected. Please select it before proceeding.";
  } else if (!vatTypePreference) {
    tooltipText =
      "VAT type has not been selected. Please select it before proceeding.";
  } else if (!languagePreference) {
    tooltipText =
      "Language has not been selected. Please select it before proceeding.";
  }

  return tooltipText;
};
