import React, { useEffect } from "react";
import { Tabs, Tab, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import ContactSyncRules from "./ContactSyncRules";
import ContactDefaultMappings from "./ContactDefaultMappings";
import OrganizationFieldMappings from "./OrganizationFieldMappings";
import PersonFieldMappings from "./PersonFieldMappings";

import {
  onChangeContactActiveTab,
  clearDefaultValues,
  updateGuideSteps,
  updateSettingsStoredKey,
  updateShowSettingsTour,
} from "generic";

import { IPreferenceStates } from "generic/types/preferencetypes";
import { IContactStates } from "generic/types/contactTypes";
import { IFortnoxStates } from "fortnox-frontend/types/fortnoxTypes";
import { IMappingSlice } from "generic/types/mappingTypes";

import {
  contactSyncRulesSteps,
  contactDefaultMappingsSteps,
} from "../../../helpers/steps/settings";

const Contact = () => {
  const dispatch = useDispatch();
  const {
    isAppConnected,
    contactActiveTab,
    settingsActiveTab,
    settingsStoredKey,
  } = useSelector(
    (state: { preference: IPreferenceStates }) => state.preference
  );
  const {
    isContactSyncRulesSaved,
    isContactCheckbox,
    customerNumberPreference,
  } = useSelector((state: { contact: IContactStates }) => state.contact);
  const { isContactDefaultMappingsSaved } = useSelector(
    (state: { fortnox: IFortnoxStates }) => state.fortnox
  );
  const {
    isMappingSaved,
    mapping: { loading: statusLoading },
  } = useSelector((state: { mappings: IMappingSlice }) => state.mappings);

  const contactSyncRulesGuide = [...contactSyncRulesSteps];
  if (customerNumberPreference.value === "action") {
    contactSyncRulesGuide.pop();
  } else if (customerNumberPreference.value === "trigger") {
    contactSyncRulesGuide.splice(-2, 1);
  }

  useEffect(() => {
    // Updates the settings stored key
    if (settingsActiveTab === "contact" && contactActiveTab) {
      dispatch(
        updateSettingsStoredKey({
          key: `pd_fortnox_settings_${settingsActiveTab}_${contactActiveTab}`,
        })
      );
    }
  }, [contactActiveTab, settingsActiveTab]);

  useEffect(() => {
    // Enables or disables tour guide
    if (settingsActiveTab === "contact" && contactActiveTab) {
      const isTourGuide = localStorage.getItem(settingsActiveTab);

      !isTourGuide || isTourGuide === "false"
        ? dispatch(updateShowSettingsTour({ value: false }))
        : dispatch(updateShowSettingsTour({ value: true }));
    }
  }, [contactActiveTab, settingsStoredKey]);

  useEffect(() => {
    // Updates the guide steps
    if (settingsActiveTab === "contact") {
      if (contactActiveTab === "syncRules") {
        console.log(contactSyncRulesGuide);
        dispatch(updateGuideSteps({ steps: contactSyncRulesGuide }));
      } else if (contactActiveTab === "defaultMappings") {
        dispatch(updateGuideSteps({ steps: contactDefaultMappingsSteps }));
      } else {
        dispatch(updateGuideSteps({ steps: [] }));
      }
    }
  }, [contactActiveTab, settingsActiveTab, customerNumberPreference]);

  return (
    <div className="settings-nested-tabs mt-2">
      <Tabs
        style={{ top: "69px" }}
        defaultActiveKey="syncRules"
        activeKey={contactActiveTab}
        onSelect={(selectedKey) => {
          dispatch(clearDefaultValues());
          dispatch(
            onChangeContactActiveTab({
              selectedTab: selectedKey,
              isAppConnected,
              isContactSyncRulesSaved,
              isContactDefaultMappingsSaved,
              isContactMappingsSaved: isMappingSaved?.customerSync,
              setupLoading: statusLoading,
            })
          );
          window.scrollTo(0, 0);
        }}
      >
        <Tab
          title={
            <span className="customer-sync-rules-tab">Customer Sync Rules</span>
          }
          eventKey="syncRules"
        >
          <ContactSyncRules />
        </Tab>
        <Tab
          title={
            <span className="customer-default-mappings-tab">
              Customer Default Mappings
            </span>
          }
          eventKey="defaultMappings"
        >
          <ContactDefaultMappings />
        </Tab>
        <Tab
          title="Organization Field Mappings"
          eventKey="companyFieldMappings"
        >
          <OrganizationFieldMappings />
        </Tab>
        {isContactCheckbox ? (
          <Tab title="Person Field Mappings" eventKey="contactFieldMappings">
            <PersonFieldMappings />
          </Tab>
        ) : (
          <Tab
            title={
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip style={{ fontSize: "12px" }}>
                    Person field mappings only applicable when contact sync
                    option is selected.
                  </Tooltip>
                }
              >
                <span>Person Field Mappings</span>
              </OverlayTrigger>
            }
          ></Tab>
        )}
      </Tabs>
    </div>
  );
};

export default Contact;
