import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import InvoiceSyncRules from "../components/install/InvoiceSyncRules";
import CustomerSearchRules from "../components/install/CustomerSearchRules";
import ProductSyncRules from "../components/install/ProductSyncRules";

import {
  InstallFlowSyncRules,
  BookConsultation,
  Loading,
  onChangeCurrentWindow,
} from "pipedrive-frontend";
import {
  saveCustomerSyncRules,
  saveProductSyncRules,
  setShowErrorModal,
  fetchStatus,
} from "generic";

import { CmsRichText } from "cms";

import { ICmsData } from "cms/types/cmsTypes";
import { IPdInitialState } from "pipedrive-frontend/types/types";
import { IFortnoxStates } from "fortnox-frontend/types/fortnoxTypes";

const SyncRules = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    cmsData: { loading: cmsLoading },
  } = useSelector((state: { cms: ICmsData }) => state.cms);

  const {
    fields: { loading: pipedriveLoading },
    pipedriveUserIds,
  } = useSelector((state: { pipedrive: IPdInitialState }) => state.pipedrive);

  const {
    fields: { loading: fxLoading },
  } = useSelector((state: { fortnox: IFortnoxStates }) => state.fortnox);

  const dispatch = useDispatch();

  const page = searchParams.get("page");

  const searchFilterOptionKeys = ["organization", "person"];
  const triggerKeys = ["deal"];

  const onShowActiveWindow = () => {
    if (page === "invoiceSyncRules") {
      return <InvoiceSyncRules />;
    } else if (page === "customerSearchRules") {
      return <CustomerSearchRules />;
    } else {
      return <ProductSyncRules />;
    }
  };

  useEffect(() => {
    const companyId = searchParams.get("companyId");
    const userId = searchParams.get("userId");
    if (page === "connectPage") {
      navigate(`../setup?companyId=${companyId}&userId=${userId}&page=connect`);
      dispatch(onChangeCurrentWindow({ currentWindow: "Connect Window" }));
    }
  }, [page]);

  useEffect(() => {
    if (pipedriveUserIds && Object.keys(pipedriveUserIds).length > 0) {
      dispatch(fetchStatus({ userIds: pipedriveUserIds }));
    }
  }, []);

  return (
    <Container>
      {cmsLoading || pipedriveLoading || fxLoading ? (
        <Loading />
      ) : (
        <Row className="d-flex justify-content-between">
          <InstallFlowSyncRules
            page={searchParams.get("page")}
            onShowActiveWindow={onShowActiveWindow}
            actionAppName="Fortnox"
            saveCustomerSyncRules={saveCustomerSyncRules}
            saveProductSyncRules={saveProductSyncRules}
            setShowErrorModal={setShowErrorModal}
            searchFilterOptionKeys={searchFilterOptionKeys}
            triggerKeys={triggerKeys}
            triggerAppName="pipedrive"
          />
          <Col md={5} className="mt-5 d-flex flex-column align-items-end">
            <div style={{ width: "90%" }}>
              {(page === "invoiceSyncRules" ||
                page === "customerSearchRules" ||
                page === "productSyncRules") && (
                <CmsRichText
                  path={`cmsContent.install.${page}.guideContent`}
                  cssName="pipedrive"
                />
              )}
            </div>
            <div style={{ width: "90%" }} className="mt-4">
              <BookConsultation CmsRichText={CmsRichText} />
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default SyncRules;
