import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import axios, { AxiosError } from "axios";
import { getUrl } from "../helpers/url";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  onChangeCurrentWindow,
  updatePipedriveUserIds,
  Connection,
  PdButton,
  ConnectedAccount,
  Loading,
} from "pipedrive-frontend";
import {
  connectApp,
  setShowErrorModal,
  setShowSuccessModal,
  fetchStatus,
} from "generic";

import { CmsRichText } from "cms";

import { ICmsData } from "cms/types/cmsTypes";
import { IPreferenceStates } from "generic/types/preferencetypes";
import { IPdInitialState } from "pipedrive-frontend/types/types";

import fortnoxLogo from "../assets/images/fortnoxFullLogo.png";

const Connect = () => {
  const [company, setCompanyId] = useState(null);
  const [user, setUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disConnectLoading, setDisConnectLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const path = window.location.pathname;

  const {
    cmsData: { loading: cmsLoading },
  } = useSelector((state: { cms: ICmsData }) => state.cms);

  const { isAppConnected, connectedMail } = useSelector(
    (state: { preference: IPreferenceStates }) => state.preference
  );

  const { pipedriveUserIds } = useSelector(
    (state: { pipedrive: IPdInitialState }) => state.pipedrive
  );

  const accordionFor = {
    customerSync: true,
    invoiceSync: true,
    products: false,
    productFields: false,
  };

  const actionAppKeys = ["customer", "invoice"];

  const changeCurrentWindow = () => {
    const companyId = searchParams.get("companyId");
    const userId = searchParams.get("userId");
    navigate(
      `../${window.location.pathname}?companyId=${companyId}&userId=${userId}&page=subscriptionPage`
    );
    dispatch(onChangeCurrentWindow({ currentWindow: "Subscription Window" }));
  };

  const connectFortnoxAccount = () => {
    const companyId = searchParams.get("companyId");
    const userId = searchParams.get("userId");
    try {
      setLoading(true);
      const url = `https://apps.fortnox.se/oauth-v1/auth?client_id=${process.env.REACT_APP_FORTNOX_CLIENT_ID}&redirect_uri=${window.location.origin}/redirect&scope=invoice%20profile%20customer%20currency%20settings%20article%20price%20developerapi%20costcenter%20order&state=pipedriveFortnox_${companyId}_${userId}&response_type=code&access_type=offline`;
      const ref = window.open(url, "_blank") as Window;

      window.addEventListener("message", (event) => {
        const { message, companyId, userId } = event.data;
        if (companyId && userId) {
          setCompanyId(companyId);
          setUserId(userId);
        }
        if (message === "closeTheTab") {
          ref.close();
          setLoading(false);
        } else if (message === "code or token not received") {
          ref.close();
          setLoading(false);
          dispatch(
            setShowErrorModal({
              message:
                "There was a problem while connecting your Fortnox Account.",
            })
          );
        }
      });
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error instanceof Error) errorMessage = error.message;
      if (
        error instanceof AxiosError &&
        error.response &&
        error.response.data.message
      )
        errorMessage = error.response.data.message;
      dispatch(
        setShowErrorModal({
          message: errorMessage,
        })
      );
    }
  };

  const connectFortnox = () => {
    return (
      <div>
        <CmsRichText
          path="cmsContent.authenticationPage.title"
          cssName="pipedrive"
        />
        <img src={fortnoxLogo} alt="Fortnox logo" className="mt-4 mb-5" />
        {isAppConnected && (
          <ConnectedAccount
            buttonText="Unlink"
            connectedAccount={connectedMail}
            disConnectAccount={disconnectAccount}
            disConnectBtnLoading={disConnectLoading}
          />
        )}
        {!isAppConnected ? (
          <>
            <CmsRichText
              path="cmsContent.authenticationPage.disconnected"
              cssName="pipedrive"
            />
          </>
        ) : (
          <CmsRichText
            path="cmsContent.authenticationPage.connected"
            cssName="pipedrive"
          />
        )}
        {path === "/setup" && (
          <div className="my-4">
            <PdButton
              text={`${isAppConnected ? "Continue" : "Connect Fortnox"}`}
              click={
                isAppConnected ? changeCurrentWindow : connectFortnoxAccount
              }
              className="fortnox-connect-button"
            />
          </div>
        )}
        {path === "/syncSettings" && !isAppConnected && (
          <div className="my-4">
            <PdButton text="Connect Fortnox" click={connectFortnoxAccount} />
          </div>
        )}
        <p className="mt-3 mb-4">
          Need help? Check the{" "}
          <a href="/setupguide" target="_blank" rel="noreferrer">
            setup guide
          </a>
        </p>
      </div>
    );
  };

  const disconnectAccount = async () => {
    try {
      setDisConnectLoading(true);
      await axios.post(getUrl("REACT_APP_DISCONNECT_ECONOMIC"), {
        ...pipedriveUserIds,
      });

      dispatch(connectApp());

      dispatch(
        setShowSuccessModal({
          message: "Your Fortnox account has been disconnected successfully",
        })
      );
      setCompanyId(null);
      setUserId(null);
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error instanceof Error) errorMessage = error.message;
      if (
        error instanceof AxiosError &&
        error.response &&
        error.response.data.message
      )
        errorMessage = error.response.data.message;
      dispatch(
        setShowErrorModal({
          message: errorMessage,
        })
      );
    } finally {
      setDisConnectLoading(false);
    }
  };

  useEffect(() => {
    const companyId = searchParams.get("companyId");
    const userId = searchParams.get("userId");
    if (companyId && path === "/syncSettings") {
      dispatch(updatePipedriveUserIds({ companyId, userId }));
    }
  }, []);

  useEffect(() => {
    if (pipedriveUserIds && Object.keys(pipedriveUserIds).length > 0) {
      dispatch(
        fetchStatus({
          userIds: pipedriveUserIds,
          accordionFor,
          fieldKeys: actionAppKeys,
        })
      );
    }
  }, [pipedriveUserIds, company, user]);

  return (
    <Container>
      {cmsLoading || loading ? (
        <Loading />
      ) : (
        <>
          <div className={`${path === "/syncSettings" ? "my-3" : ""}`}>
            <Connection
              actionAppConnection={connectFortnox}
              CmsRichText={CmsRichText}
              isIframe={path === "/syncSettings" ? true : false}
            />
          </div>
        </>
      )}
    </Container>
  );
};

export default Connect;
