import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { fetchStatus, setShowErrorModal } from "generic";

import { getUrlInFortnox } from "../helpers/url";

import { IFortnoxStates, IFortnoxProps } from "../types/fortnoxTypes";

const initialState: IFortnoxStates = {
  fields: {
    loading: false,
    data: {},
    error: "",
    currentRequestId: null,
  },
  fortnoxDefaultMappings: {
    loading: false,
    data: {},
    error: "",
  },
  defaultMappings: {
    paymentTermPreference: undefined,
    currencyPreference: undefined,
    languagePreference: undefined,
    listOfPricePreference: undefined,
    termsOfDeliveryPreference: undefined,
    waysOfDeliveryPreference: undefined,
    vatTypePreference: undefined,
  },
  isContactDefaultMappingsSaved: false,
};

export const fetchFortnoxFields = createAsyncThunk(
  "fortnox/fetchFortnoxFields",
  async (props: IFortnoxProps, { dispatch }) => {
    const { userIds } = props;
    try {
      const res = await axios.get(
        getUrlInFortnox("REACT_APP_FETCH_ACTION_APP_FIELDS"),
        {
          params: userIds,
        }
      );
      return res.data;
    } catch (error) {
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(
        setShowErrorModal({
          message: errorMessage,
        })
      );
      throw error;
    }
  }
);

export const fetchFortnoxDefaultFields = createAsyncThunk(
  "fortnox/fetchFortnoxDefaultFields",
  async (props: IFortnoxProps, { dispatch }) => {
    const { userIds } = props;
    try {
      const res = await axios.get(
        getUrlInFortnox("REACT_APP_FETCH_ACTION_APP_DEFAULT_FIELDS"),
        { params: userIds }
      );
      return res.data;
    } catch (error) {
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(
        setShowErrorModal({
          message: errorMessage,
        })
      );
      throw error;
    }
  }
);

export const fortnoxSlice = createSlice({
  name: "fortnox",
  initialState,
  reducers: {
    onChangeFortnoxDefaultMappings(state, { payload }) {
      if (payload.selectingFor === "payment terms") {
        state.defaultMappings.paymentTermPreference = {
          name: payload.selectedOption.title,
          value: payload.selectedOption.value,
        };
        state.isContactDefaultMappingsSaved = false;
      } else if (payload.selectingFor === "currency") {
        state.defaultMappings.currencyPreference = {
          name: payload.selectedOption.title,
          value: payload.selectedOption.value,
        };
        state.isContactDefaultMappingsSaved = false;
      } else if (payload.selectingFor === "price list") {
        state.defaultMappings.listOfPricePreference = {
          name: payload.selectedOption.title,
          value: payload.selectedOption.value,
        };
        state.isContactDefaultMappingsSaved = false;
      } else if (payload.selectingFor === "delivery ways") {
        state.defaultMappings.waysOfDeliveryPreference = {
          name: payload.selectedOption.title,
          value: payload.selectedOption.value,
        };
        state.isContactDefaultMappingsSaved = false;
      } else if (payload.selectingFor === "delivery terms") {
        state.defaultMappings.termsOfDeliveryPreference = {
          name: payload.selectedOption.title,
          value: payload.selectedOption.value,
        };
        state.isContactDefaultMappingsSaved = false;
      } else if (payload.selectingFor === "VAT type") {
        state.defaultMappings.vatTypePreference = {
          name: payload.selectedOption.title,
          value: payload.selectedOption.value,
        };
        state.isContactDefaultMappingsSaved = false;
      } else if (payload.selectingFor === "language") {
        state.defaultMappings.languagePreference = {
          name: payload.selectedOption.title,
          value: payload.selectedOption.value,
        };
        state.isContactDefaultMappingsSaved = false;
      }
    },

    setIsContactDefaultMappings(state) {
      state.isContactDefaultMappingsSaved = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFortnoxFields.pending, (state, action) => {
      if (!state.fields.loading) {
        state.fields.loading = true;
        state.fields.currentRequestId = action.meta.requestId;
      }
    });

    builder.addCase(fetchFortnoxFields.fulfilled, (state, action) => {
      if (
        state.fields.loading &&
        state.fields.currentRequestId === action.meta.requestId
      ) {
        state.fields.loading = false;
        state.fields.data = action.payload;
        state.fields.error = "";
        state.fields.currentRequestId = null;
      }
    });

    builder.addCase(fetchFortnoxFields.rejected, (state, action) => {
      if (
        state.fields.loading &&
        state.fields.currentRequestId === action.meta.requestId
      ) {
        state.fields.loading = false;
        state.fields.data = {};
        state.fields.error = action.error.message || "Something went wrong";
        state.fields.currentRequestId = null;
      }
    });

    builder.addCase(fetchFortnoxDefaultFields.pending, (state) => {
      if (!state.fortnoxDefaultMappings.loading) {
        state.fortnoxDefaultMappings.loading = true;
      }
    });
    builder.addCase(fetchFortnoxDefaultFields.fulfilled, (state, action) => {
      if (state.fortnoxDefaultMappings.loading) {
        state.fortnoxDefaultMappings.loading = false;
        state.fortnoxDefaultMappings.data = action.payload;
        state.fortnoxDefaultMappings.error = "";
      }
    });
    builder.addCase(fetchFortnoxDefaultFields.rejected, (state, action) => {
      if (state.fortnoxDefaultMappings.loading) {
        state.fortnoxDefaultMappings.loading = false;
        state.fortnoxDefaultMappings.data = {};
        state.fortnoxDefaultMappings.error = action.error.message ?? "";
      }
    });

    builder.addCase(fetchStatus.fulfilled, (state, action) => {
      const { preferences } = action.payload;
      const { customerSync } = preferences;
      const { defaultFields } = customerSync;

      if (defaultFields && Object.keys(defaultFields).length > 0) {
        const defaultFieldsData = {
          paymentTermPreference: defaultFields?.paymentTerms?.value
            ? defaultFields?.paymentTerms
            : undefined,
          currencyPreference: defaultFields?.currencies?.value
            ? defaultFields?.currencies
            : undefined,
          languagePreference: defaultFields?.languages?.value
            ? defaultFields?.languages
            : undefined,
          listOfPricePreference: defaultFields?.priceList?.value
            ? defaultFields?.priceList
            : undefined,
          termsOfDeliveryPreference: defaultFields?.deliveryTerms?.value
            ? defaultFields?.deliveryTerms
            : undefined,
          waysOfDeliveryPreference: defaultFields?.deliveryWays?.value
            ? defaultFields?.deliveryWays
            : undefined,
          vatTypePreference: defaultFields?.vatTypes?.value
            ? defaultFields?.vatTypes
            : undefined,
        };

        state.defaultMappings = defaultFieldsData;

        if (
          state.defaultMappings.paymentTermPreference &&
          state.defaultMappings.currencyPreference &&
          state.defaultMappings.languagePreference &&
          state.defaultMappings.listOfPricePreference &&
          state.defaultMappings.termsOfDeliveryPreference &&
          state.defaultMappings.vatTypePreference &&
          state.defaultMappings.waysOfDeliveryPreference
        ) {
          state.isContactDefaultMappingsSaved = true;
        }
      }
    });
  },
});

export const { onChangeFortnoxDefaultMappings, setIsContactDefaultMappings } =
  fortnoxSlice.actions;

export default fortnoxSlice.reducer;
