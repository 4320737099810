import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Loading, PdNavbar, updatePipedriveUserIds } from "pipedrive-frontend";
import { setShowErrorModal, setShowSuccessModal, Retrigger } from "generic";

import {
  fetchCmsData,
  onChangeIsUserAllowed,
  CmsEditAndSave,
  CmsRichText,
} from "cms";
import { useDispatch, useSelector } from "react-redux";
import hsEcoLogo from "./../assets/images/hs-eco-logo 3.svg";

import { IPdInitialState } from "pipedrive-frontend/types/types";
import { ICmsData } from "cms/types/cmsTypes";

const RetriggerPage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { pipedriveUserIds } = useSelector(
    (state: { pipedrive: IPdInitialState }) => state.pipedrive
  );
  const {
    allowedUsers,
    cmsData: { loading: cmsLoading },
  } = useSelector((state: { cms: ICmsData }) => state.cms);

  const retriggerStages: { [k: string]: { [k: string]: unknown } } = {
    triggered: {
      label: "stage1",
      value: undefined,
    },
    findCreateCompanies: {
      label: "stage2",
      value: undefined,
    },
    findCreateProducts: {
      label: "stage3",
      value: undefined,
    },
    syncDeal: {
      label: "stage4",
      value: undefined,
    },
  };

  useEffect(() => {
    const companyId = searchParams.get("companyId");
    const userId = searchParams.get("userId");
    if (companyId) {
      dispatch(updatePipedriveUserIds({ companyId, userId }));
    }
  }, []);

  useEffect(() => {
    dispatch(fetchCmsData());
  }, []);

  useEffect(() => {
    if (allowedUsers.length > 0) {
      dispatch(onChangeIsUserAllowed({ userId: pipedriveUserIds.userId }));
    }
  }, [allowedUsers]);

  const retriggerBody = {
    ...pipedriveUserIds,
    objectId: searchParams.get("objectId"),
    generate: searchParams.get("generate"),
  };

  return (
    <div>
      <PdNavbar logo={hsEcoLogo} altText="Fortnox sync" text="Fortnox sync" />
      {cmsLoading ? (
        <Loading />
      ) : (
        <>
          <div className="d-flex justify-content-center my-5">
            <CmsEditAndSave
              userIds={pipedriveUserIds}
              appSlug="pipedriveFortnox-quick-install"
              setShowErrorModal={setShowErrorModal}
              setShowSuccessModal={setShowSuccessModal}
              editNotificationPath={`userId=${searchParams.get(
                "userId"
              )}&appSlug=pipedriveFortnox-quick-install`}
            />
          </div>
          <Retrigger
            CmsRichText={CmsRichText}
            actionAppName="Fortnox"
            retriggerStages={retriggerStages}
            triggerAppName="Pipedrive"
            retriggerBody={retriggerBody}
          />
        </>
      )}
    </div>
  );
};

export default RetriggerPage;
