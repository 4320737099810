import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios, { AxiosError } from "axios";
import { Container } from "react-bootstrap";

import { getUrl } from "../helpers/url";

import { setShowSuccessModal, setShowErrorModal, connectApp } from "generic";
import { Loading } from "pipedrive-frontend";

interface IBodyProps {
  code?: string;
  companyId?: number;
  userId?: number;
  token?: string;
  error?: string;
  parameters?: { [key: string]: string };
}

const Redirect = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const [oauthLoading, setOauthLoading] = useState(false);

  /**
   * Store FortnoxCreds
   * @param body
   */
  const storeFortnoxCreds = async (body: IBodyProps) => {
    setOauthLoading(true);
    const { code, token, parameters, error } = body;

    const extractIds = token?.match(/\d+/g);
    let companyId: number | null = null;
    let userId: number | null = null;
    if (extractIds && extractIds.length >= 2) {
      companyId = parseInt(extractIds[0]);
      userId = parseInt(extractIds[1]);
    }
    try {
      if (code !== "") {
        const res = await axios.post(getUrl("REACT_APP_SAVE_CREDS"), {
          code,
          companyId,
          userId,
          parameters,
        });
        if (res.status === 200) {
          dispatch(connectApp());
        }
        dispatch(
          setShowSuccessModal({
            message: "Fortnox connected successfully.",
          })
        );
      } else {
        await axios.post(getUrl("REACT_APP_SAVE_CREDS"), {
          parameters,
        });
      }
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error instanceof Error) errorMessage = error.message;
      if (
        error instanceof AxiosError &&
        error.response &&
        error.response.data.message
      )
        errorMessage = error.response.data.message;
      dispatch(
        setShowErrorModal({
          message: errorMessage,
          slug: "redirect",
        })
      );
    } finally {
      if (error === "error") {
        const timeoutId = setTimeout(() => {
          window.opener.postMessage(
            {
              message: "code or token not received",
              companyId: "",
              userId: "",
            },
            "*"
          );
          clearTimeout(timeoutId);
        }, 3000);
      } else {
        const timeoutId = setTimeout(() => {
          window.opener.postMessage(
            { message: "closeTheTab", companyId, userId },
            "*"
          );
          clearTimeout(timeoutId);
        }, 3000);
      }
      setOauthLoading(false);
    }
  };

  useEffect(() => {
    const code = searchParams.get("code") as string;
    const state = searchParams.get("state") as string;
    const params: { [key: string]: string } = {};
    const queryParams = window.location.search.substring(1);
    const keyValuePairs = queryParams.split("&");
    keyValuePairs.forEach((keyValue) => {
      const [key, value] = keyValue.split("=");
      params[key] = value;
    });
    if (code && state) {
      const body: IBodyProps = {
        code: code,
        token: state,
        parameters: params,
      };
      storeFortnoxCreds(body);
    } else {
      const error = "error";
      const paramsBody: IBodyProps = { parameters: params, error };
      storeFortnoxCreds(paramsBody);
    }
  }, []);

  useEffect(() => {
    const paymentStatus = searchParams.get("paymentStatus");
    const plan = searchParams.get("plan");

    if (paymentStatus && plan) {
      window.opener.postMessage({ paymentStatus, plan }, "*");
      window.close();
    } else if (paymentStatus) {
      window.opener.postMessage({ paymentStatus }, "*");
      window.close();
    }
  }, []);
  return (
    <div>
      <Container style={{ height: "100vh", width: "100px" }}>
        {oauthLoading && <Loading />}
      </Container>
    </div>
  );
};

export default Redirect;
