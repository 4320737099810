import React from "react";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import {
  onChangePreferenceForSearchParameters,
  onChangeProductPreferences,
  onChangePreferenceForProducts,
  onChangeIsOverAllDiscountPresent,
  SetupDropdown,
  onUpdateAdvancedStatus,
} from "generic";

import { CmsRichText } from "cms";
import CustomProductMapping from "./CustomProductMapping";
import { onChangeDealsSyncCheckbox } from "generic";
import arrowIcon from "../../assets/images/arrowDown.svg";

import { IProductStates } from "generic/types/productTypes";
import { IPdInitialState } from "pipedrive-frontend/types/types";
import { IFortnoxStates } from "fortnox-frontend/types/fortnoxTypes";

const ProductSyncRules = () => {
  const dispatch = useDispatch();

  const {
    preferenceForProducts,
    preferenceForSearchParameters,
    productPreference,
    triggerAppProductFilterPreference,
    actionAppProductFilterPreference,
    dealsSyncCheckbox,
    dealSyncProductPreference,
    isOverAllDiscountPresent,
    overAllDiscountProductPreference,
  } = useSelector((state: { products: IProductStates }) => state.products);

  const {
    fields: {
      data: { productSearchFields: triggerAppProductSearchFields },
    },
  } = useSelector((state: { pipedrive: IPdInitialState }) => state.pipedrive);

  const {
    fields: {
      data: {
        productSearchFields: fxProductSearchFields,
        products: fxProducts,
      },
    },
  } = useSelector((state: { fortnox: IFortnoxStates }) => state.fortnox);

  return (
    <div>
      <div>
        <CmsRichText
          path="cmsContent.install.productSyncRules.headerContent"
          cssName="pipedrive"
        />
      </div>
      <div className="mt-4">
        <div>
          {/* Search Filter options */}
          <div className="pd-fortnox-product-search-parameters">
            <Form.Check
              type="radio"
              id="selectSearchParameters"
              label="Simple Search: Match products using a specific field."
              name="productSearchParameters"
              value="select search parameters"
              className="mb-2 install-form-check-label"
              checked={
                preferenceForSearchParameters === "select search parameters"
              }
              onChange={(e) => {
                dispatch(
                  onChangePreferenceForSearchParameters({
                    selected: e.target.value,
                  })
                );
              }}
            />
          </div>
          {preferenceForSearchParameters === "select search parameters" && (
            <div className="ms-4">
              <CmsRichText
                path="cmsContent.install.productSyncRules.searchParameters"
                cssName="pipedrive"
              />
              <div className="d-flex flex-row align-items-center flex-wrap mt-3">
                <div>
                  <label className="install-label-text">Pipedrive</label>
                  <SetupDropdown
                    fieldItems={triggerAppProductSearchFields}
                    selectedValue={triggerAppProductFilterPreference}
                    onChangeValue={onChangeProductPreferences}
                    dropdownFor="triggerAppProductFilterField"
                    dropdownLabel="Pipedrive"
                    dropdownWidth="300px"
                    dropdownMenuWidth="250px"
                    cssName="install-flow-dropdown"
                  />
                </div>
                <div className="mx-3 h-100">
                  <img src={arrowIcon} alt="arrow icon" className="mt-4" />
                </div>
                <div>
                  <label className="install-label-text">Fortnox</label>
                  <SetupDropdown
                    fieldItems={fxProductSearchFields}
                    selectedValue={actionAppProductFilterPreference}
                    onChangeValue={onChangeProductPreferences}
                    dropdownFor="actionAppProductFilterField"
                    dropdownLabel="Fortnox"
                    dropdownWidth="300px"
                    dropdownMenuWidth="250px"
                    cssName="install-flow-dropdown"
                  />
                </div>
              </div>
            </div>
          )}

          {/* Custom Product Mapping */}
          <div className="pd-fortnox-custom-product-mapping">
            <Form.Check
              type="radio"
              id="setCustomMappings"
              label="Direct Link: Connect products directly without searching."
              name="productSearchParameters"
              value="set custom mappings"
              className="mt-3 install-form-check-label"
              checked={preferenceForSearchParameters === "set custom mappings"}
              onChange={(e) => {
                dispatch(
                  onChangePreferenceForSearchParameters({
                    selected: e.target.value,
                  })
                );
              }}
            />
          </div>
          {preferenceForSearchParameters === "set custom mappings" && (
            <div className="ms-4 my-2">
              <CmsRichText
                path="cmsContent.install.productSyncRules.customProductMapping"
                cssName="pipedrive"
              />
              <div className="mt-3">
                <CustomProductMapping />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mt-4">
        <CmsRichText
          path="cmsContent.install.productSyncRules.advancedSettings.title"
          cssName="pipedrive"
        />
      </div>

      <details
        className="mt-4 pd-fortnox-advanced-settings"
        onToggle={(e) =>
          dispatch(
            onUpdateAdvancedStatus({
              status: (e.target as HTMLDetailsElement).open,
            })
          )
        }
      >
        <summary>Advanced settings</summary>
        <div className="my-3">
          <CmsRichText
            path="cmsContent.install.productSyncRules.advancedSettings.description"
            cssName="pipedrive"
          />
        </div>
        {/* Do not generate invoice */}
        <div className="mt-3">
          <div className="pd-fortnox-do-not-generate-invoice">
            <Form.Check
              type="radio"
              id="doNotGenerateInvoice"
              label="Skip invoice generation: Don't create an invoice if the product isn't found."
              name="productPreference"
              value="do not generate invoice"
              checked={preferenceForProducts === "do not generate invoice"}
              onChange={(e) => {
                dispatch(
                  onChangePreferenceForProducts({
                    selected: e.target.value,
                  })
                );
              }}
              className="install-form-check-label"
            />
          </div>
          {preferenceForProducts === "do not generate invoice" && (
            <CmsRichText
              path="cmsContent.install.productSyncRules.invoiceGeneration"
              cssName="pipedrive ms-4"
            />
          )}
        </div>
        {/* Use default product */}
        <div className="mt-3">
          <div className="pd-fortnox-use-default-product">
            <Form.Check
              type="radio"
              id="useDefaultProduct"
              label="Use a default article: Replace the missing product with a predefined article in Fortnox."
              name="productPreference"
              value="select product"
              checked={preferenceForProducts === "select product"}
              onChange={(e) => {
                dispatch(
                  onChangePreferenceForProducts({
                    selected: e.target.value,
                  })
                );
              }}
              className="install-form-check-label"
            />
          </div>
          {preferenceForProducts === "select product" && (
            <div className="mt-2 ms-4">
              <CmsRichText
                path="cmsContent.install.productSyncRules.defaultProduct"
                cssName="pipedrive"
              />
              <label className="">Select article</label>
              {fxProducts && fxProducts.length > 0 && (
                <SetupDropdown
                  fieldItems={fxProducts}
                  selectedValue={productPreference}
                  onChangeValue={onChangeProductPreferences}
                  dropdownFor="products"
                  cssName="install-flow-dropdown"
                  dropdownLabel="article"
                />
              )}
            </div>
          )}
        </div>
        {/* Find Create product */}
        <div className="mt-3">
          <div className="pd-fortnox-find-create-product">
            <Form.Check
              type="radio"
              id="findCreateProduct"
              label="Create a new article in Fortnox"
              name="productPreference"
              value="find create product"
              checked={preferenceForProducts === "find create product"}
              onChange={(e) => {
                dispatch(
                  onChangePreferenceForProducts({
                    selected: e.target.value,
                  })
                );
              }}
              className="install-form-check-label"
            />
          </div>
          {preferenceForProducts === "find create product" && (
            <div className="mt-2 ms-4">
              <CmsRichText
                path="cmsContent.install.productSyncRules.createNewProduct"
                cssName="pipedrive"
              />
            </div>
          )}
        </div>
      </details>

      <hr className="my-4" />

      {/* Sync deals without products */}
      <div className="my-4 pd-fortnox-deal-without-products">
        <div>
          <CmsRichText
            path="cmsContent.install.productSyncRules.missingLineItems.title"
            cssName="pipedrive"
          />
        </div>
        <Form.Check
          type="checkbox"
          id="dealSyncInstall"
          label="Sync deals which do not have products associated with them"
          checked={dealsSyncCheckbox}
          onChange={() => dispatch(onChangeDealsSyncCheckbox())}
          className="install-form-check-label"
        />
        {dealsSyncCheckbox && (
          <div className="mt-4">
            <CmsRichText
              path="cmsContent.install.productSyncRules.missingLineItems.description"
              cssName="pipedrive"
            />
            {fxProducts && fxProducts.length > 0 && (
              <div className="mt-3">
                <SetupDropdown
                  fieldItems={fxProducts}
                  dropdownFor="dealSyncProduct"
                  selectedValue={dealSyncProductPreference}
                  onChangeValue={onChangeProductPreferences}
                  cssName="install-flow-dropdown"
                  dropdownLabel="article"
                />
              </div>
            )}
          </div>
        )}
      </div>
      {/* Handle overall discounts */}
      <div className="my-4 pd-fortnox-overall-discount">
        <div>
          <CmsRichText
            path="cmsContent.install.productSyncRules.overAllDiscount.title"
            cssName="pipedrive"
          />
        </div>
        <Form.Check
          type="checkbox"
          id="overallDiscount"
          label="Select a default article to add over all discount as a line item"
          checked={isOverAllDiscountPresent}
          onChange={() => dispatch(onChangeIsOverAllDiscountPresent())}
          className="install-form-check-label"
        />
        {isOverAllDiscountPresent && (
          <div className="mt-4">
            <CmsRichText
              path="cmsContent.install.productSyncRules.overAllDiscount.description"
              cssName="pipedrive"
            />
            {fxProducts && fxProducts.length > 0 && (
              <div className="mt-3">
                <SetupDropdown
                  fieldItems={fxProducts}
                  dropdownFor="overAllDiscountProduct"
                  selectedValue={overAllDiscountProductPreference}
                  onChangeValue={onChangeProductPreferences}
                  cssName="install-flow-dropdown"
                  dropdownLabel="article"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductSyncRules;
