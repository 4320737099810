import axios from "axios";

import { getUrlInFortnox } from "./url";

import { setIsContactDefaultMappings } from "../slice/fortnoxSlice";
import { setShowErrorModal, setShowSuccessModal } from "generic";

export const saveContactDefaultMappings = async (props) => {
  const {
    userIds,
    dispatch,
    setIsLoading,
    currencyPreference,
    paymentTermPreference,
    listOfPricePreference,
    waysOfDeliveryPreference,
    termsOfDeliveryPreference,
    vatTypePreference,
    languagePreference,
  } = props;

  try {
    setIsLoading(true);
    const data = {
      ...userIds,
      preferences: {
        defaultFields: {
          currencies: currencyPreference,
          deliveryTerms: termsOfDeliveryPreference,
          deliveryWays: waysOfDeliveryPreference,
          paymentTerms: paymentTermPreference,
          priceList: listOfPricePreference,
          vatTypes: vatTypePreference,
          languages: languagePreference,
        },
      },
      preferenceType: "customerSync",
    };
    await axios.post(getUrlInFortnox("REACT_APP_SAVE_PREFERENCES"), data);
    dispatch(setShowSuccessModal({ message: "Saved successfully" }));
    dispatch(setIsContactDefaultMappings());
  } catch (error) {
    console.log(error);
    let errorMessage;
    if (error.response && error.response.data.message)
      errorMessage = error.response.data.message;
    else errorMessage = error.message;
    dispatch(setShowErrorModal({ message: errorMessage }));
  } finally {
    setIsLoading(false);
  }
};
