import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
  InvoiceSync,
  ButtonWithTooltip,
  fetchPipelines,
  fetchDealStages,
} from "generic";
import { CmsRichText } from "cms";

import { IPdInitialState } from "pipedrive-frontend/types/types";
import { IInvoiceState } from "generic/types/invoiceTypes";

const InvoiceSyncRules = () => {
  const dispatch = useDispatch();
  const { pipedriveUserIds } = useSelector(
    (state: { pipedrive: IPdInitialState }) => state.pipedrive
  );
  const { pipelinePreference } = useSelector(
    (state: { invoice: IInvoiceState }) => state.invoice
  );

  const triggerKeys = ["deal"];

  useEffect(() => {
    if (pipedriveUserIds && Object.keys(pipedriveUserIds).length > 0) {
      dispatch(fetchPipelines({ userIds: pipedriveUserIds }));
    }
  }, [pipedriveUserIds]);

  useEffect(() => {
    if (
      pipedriveUserIds &&
      Object.keys(pipedriveUserIds).length > 0 &&
      pipelinePreference
    ) {
      const pipelineId = pipelinePreference.value;
      dispatch(fetchDealStages({ pipelineId, userIds: pipedriveUserIds }));
    }
  }, [pipelinePreference]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className="px-0">
      <Row className="mb-4">
        <Col>
          <InvoiceSync
            CmsRichText={CmsRichText}
            cardCssName="sync-settings-card"
            dropdownCssName="settings-dropdown"
            triggerAppName="Pipedrive"
            pdfLabel="Attach a pdf of invoice/order in deal"
          />
          <ButtonWithTooltip
            actionAppName="Fortnox"
            triggerAppName="Pipedrive"
            savePreferencesFor="invoiceSyncRules"
            userIds={pipedriveUserIds}
            disableBtnCssName="pd-disabled-btn"
            btnCssName="pd-save-changes-btn"
            triggerKeys={triggerKeys}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default InvoiceSyncRules;
