import React from "react";
import { useSelector } from "react-redux";
import { MappingWindow } from "generic";

import { Loading } from "pipedrive-frontend";

import { IPdInitialState } from "pipedrive-frontend/types/types";
import { IMappingSlice } from "generic/types/mappingTypes";
import { IFortnoxStates } from "fortnox-frontend/types/fortnoxTypes";

const CustomProductMapping = () => {
  const {
    mapping: {
      loading,
      defaultMappings: { products },
    },
  } = useSelector((state: { mappings: IMappingSlice }) => state.mappings);

  const {
    fields: {
      loading: pdLoading,
      data: { products: pdProducts },
    },
    pipedriveUserIds,
  } = useSelector((state: { pipedrive: IPdInitialState }) => state.pipedrive);

  const {
    fields: {
      loading: fxLoading,
      data: { products: fxProducts },
    },
  } = useSelector((state: { fortnox: IFortnoxStates }) => state.fortnox);

  const triggerAppObject = {
    products: pdProducts,
  };

  const actionAppObject = {
    products: fxProducts,
  };

  const labels: { [k: string]: string } = {
    actionApp: "Fortnox Articles",
    triggerApp: "Pipedrive Products",
  };

  const toggleText = () => {
    if (
      products &&
      pdProducts &&
      pdProducts.length === 0 &&
      fxProducts &&
      fxProducts.length === 0
    ) {
      return "Products not present in both Pipedrive and Fortnox";
    } else if (fxProducts && fxProducts.length === 0) {
      return "Products not present in Fortnox";
    } else if (pdProducts && pdProducts.length === 0) {
      return "Products not present in Pipedrive";
    }
  };

  return (
    <div>
      {!loading || !pdLoading || !fxLoading ? (
        <>
          {products &&
          pdProducts &&
          pdProducts.length > 0 &&
          fxProducts &&
          fxProducts.length > 0 ? (
            <MappingWindow
              mappings={products}
              primaryTriggerAppObjectKey="products"
              triggerAppObject={triggerAppObject}
              actionAppObject={actionAppObject}
              mappingsFor="products"
              labels={labels}
              cssClass="mapping-window"
              userIds={pipedriveUserIds}
              removeBorderThickness={true}
              isBordered={true}
            />
          ) : (
            <div className="note-container d-flex flex-row justify-content-center">
              <h5 style={{ marginBottom: "0px", fontSize: "18px" }}>
                {toggleText()}
              </h5>
            </div>
          )}
        </>
      ) : (
        <div className="d-flex flex-row justify-content-center">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default CustomProductMapping;
