import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import {
  ProductSearch,
  ButtonWithTooltip,
  ProductNotFound,
  ProductSyncDeal,
  ProductOverallDiscount,
} from "generic";
import { CmsRichText } from "cms";
import CustomProductMapping from "../../install/CustomProductMapping";

import { Loading } from "pipedrive-frontend";

import { IFortnoxStates } from "fortnox-frontend/types/fortnoxTypes";
import { IPdInitialState } from "pipedrive-frontend/types/types";

const ProductSyncRules = () => {
  const {
    fields: {
      loading: fxLoading,
      data: { products, productSearchFields: fxProductSearchFields },
    },
  } = useSelector((state: { fortnox: IFortnoxStates }) => state.fortnox);

  const {
    pipedriveUserIds,
    fields: {
      loading: pdLoading,
      data: { productSearchFields: triggerAppProductSearchFields },
    },
  } = useSelector((state: { pipedrive: IPdInitialState }) => state.pipedrive);

  return (
    <Container className="px-0">
      {fxLoading || pdLoading ? (
        <Loading />
      ) : (
        <Row className="mb-4">
          <Col>
            <ProductSearch
              triggerAppName="Pipedrive"
              actionAppName="Fortnox"
              actionAppProductSearchFields={fxProductSearchFields}
              triggerAppProductSearchFields={triggerAppProductSearchFields}
              CmsRichText={CmsRichText}
              cardCssName="sync-settings-card"
              dropdownCssName="settings-dropdown"
              CustomProductMapping={CustomProductMapping}
            />
            <ProductNotFound
              triggerAppName="Pipedrive"
              actionAppName="Fortnox"
              actionAppProducts={products}
              CmsRichText={CmsRichText}
              cardCssName="sync-settings-card"
              dropdownCssName="settings-dropdown"
              dropdownLabel="article"
            />
            <ProductSyncDeal
              triggerAppName="Pipedrive"
              actionAppName="Fortnox"
              actionAppProducts={products}
              CmsRichText={CmsRichText}
              cardCssName="sync-settings-card"
              dropdownCssName="settings-dropdown"
              dropdownLabel="article"
            />
            <ProductOverallDiscount
              triggerAppName="Pipedrive"
              actionAppName="Fortnox"
              actionAppProducts={products}
              CmsRichText={CmsRichText}
              cardCssName="sync-settings-card"
              dropdownCssName="settings-dropdown"
              dropdownLabel="article"
            />
            <ButtonWithTooltip
              actionAppName="Fortnox"
              triggerAppName="Pipedrive"
              savePreferencesFor="productSyncRules"
              preferenceType="productSync"
              userIds={pipedriveUserIds}
              disableBtnCssName="pd-disabled-btn"
              btnCssName="pd-save-changes-btn"
            />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default ProductSyncRules;
